<template>
  <div class="login">
    <h1>AI智能机器人管理系统</h1>
    <div class="login-box">
      <div class="code">
        <!-- <div class="code-text">
          {{ isCode ? "扫码登录更便捷" : "点击使用账号登录" }}
        </div> -->
        <!-- <div class="sanjiao"></div> -->
        <!-- <div @click="isCode = !isCode">
          <img
            v-if="isCode"
            style="width: 92px; height: 92px; cursor: pointer"
            src="@/assets/qr-code-trigon.png"
          />
          <img
            v-else
            style="width: 92px; height: 92px; cursor: pointer"
            src="@/assets/account-trigon.png"
          /> -->
          <!-- '@/assets/account-trigon.png' -->
        <!-- </div> -->
      </div>
      <!-- <div v-if="isCode" class="btn-code"> -->
        <!-- <div class="title-saoma">扫码登录</div> -->
        <!-- <div class="title-code"> -->
          <!-- <qrcode-vue :value="value" :size="size" level="H" /> -->
          <!-- <wxlogin
            :appid="appid"
            :state="id"
            scope="snsapi_login"
            redirect_uri="/weixin/login_callback_url"
          ></wxlogin> -->
        <!-- </div> -->
        <!-- <div class="title-open">打开微信，扫一扫登录</div> -->
        <!-- <div class="zhuce" style="margin-top: 30px">
          还没有账号？<label @click="Register">去注册</label>
        </div> -->
      <!-- </div> -->
      <div class="btn-form">
        <div class="title">
          <div
            class="item"
            @click="handleClick(item.id)"
            :class="{ active: item.id == activeName }"
            v-for="item in list"
            :key="item.id"
          >
            {{ item.title }}
          </div>
        </div>
        <a-form
          v-if="activeName == 1"
          ref="formRef"
          :model="formState"
          :rules="rules"
        >
          <a-form-item ref="name" name="name">
            <a-input
              size="large"
              v-model:value="formState.name"
              placeholder="请输入用户名"
            >
              <template #prefix>
                <user-outlined type="user" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item ref="password" name="password">
            <a-input
              size="large"
              v-model:value="formState.password"
              placeholder="请输入密码"
              type="password"
              @keyup.enter="onSubmit"
            >
              <template #prefix>
                <lock-outlined type="lock" />
              </template>
            </a-input>
          </a-form-item>
          <!-- <a-form-item> -->
          <a-button
            type="primary"
            style="margin-top: 10px"
            size="large"
            @click.prevent="onSubmit"
            >登入</a-button
          >
          <!-- </a-form-item> -->
        </a-form>
        <a-form v-else ref="formRef" :model="formState2">
          <a-form-item ref="name" name="phone">
            <a-input
              size="large"
              v-model:value="formState2.phone"
              placeholder="请输入手机号"
            >
              <template #prefix>
                <AppleOutlined />
              </template>
            </a-input>
          </a-form-item>
          <div class="sendcode">
            <a-form-item ref="password" name="code">
              <a-input
                size="large"
                v-model:value="formState2.code"
                placeholder="请输入验证码"
              >
                <template #prefix>
                  <SafetyOutlined />
                </template>
              </a-input>
            </a-form-item>
            <!-- <a-button size="large">发送验证码</a-button> -->
            <a-button size="large" :disabled="sendCodeBtn" @click="sendCode">{{
              sendCodeBtn ? `${timerPhone}秒后再操作` : "发送"
            }}</a-button>
          </div>
          <a-button
            type="primary"
            style="margin-top: 10px"
            size="large"
            @click.prevent="onSubmit2"
            >登入</a-button
          >
        </a-form>
        <!-- <div class="zhuce">
          还没有账号？<label @click="Register">去注册</label>
        </div> -->
      </div>
    </div>
    <div class="foolter">
      <p>聆听世界的声音 让AI走进行行业业</p>
    </div>
  </div>
</template>
<script>
import { toRefs, reactive, ref, onMounted, onUnmounted, watch } from "vue";
import {
  UserOutlined,
  LockOutlined,
  AppleOutlined,
  SafetyOutlined,
} from "@ant-design/icons-vue";
// import { useForm } from "@ant-design-vue/use";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import {
  login,
  getAppid,
  getResult,
  postPhoneLogin,
  getSendCode,
} from "../service/login";
// import QrcodeVue from "qrcode.vue";
// import wxlogin from "vue-wxlogin";
import { regTest } from "../utils/regExp";

export default {
  name: "Login",
  components: {
    UserOutlined,
    LockOutlined,
    SafetyOutlined,
    AppleOutlined,
    // QrcodeVue,
    // wxlogin,
  },
  setup() {
    // const activeKey = ref("1");
    const router = useRouter();
    const state = reactive({
      activeName: 1,
      isCode: true,
      size: 160,
      value: "https://baidu.com",
      list: [
        { id: 1, title: "账号登入" },
        { id: 2, title: "手机登入" },
      ],
      appid: undefined,
      id: undefined,
      sendCodeBtn: false,
      timerPhone: 60,
      timer: null,
    });
    const formRef = ref();
    const formState = reactive({
      name: "",
      password: "",
    });
    const formState2 = reactive({
      phone: "",
      code: "",
    });
    const rules = {
      name: [
        {
          required: true,
          message: "请输入用户名",
          trigger: "blur",
        },
        {
          min: 2,
          max: 15,
          message: "长度2到15个字符",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
        {
          min: 6,
          max: 12,
          message: "长度6到12位",
          trigger: "blur",
        },
      ],
    };
    function createUniqueId(n) {
      var random = function () {
        // 生成10-12位不等的字符串
        return Number(Math.random().toString().substr(2)).toString(36); // 转换成十六进制
      };
      var arr = [];
      function createId() {
        var num = random();
        var _bool = false;
        arr.forEach((v) => {
          if (v === num) _bool = true;
        });
        if (_bool) {
          createId();
        } else {
          arr.push(num);
        }
      }
      var i = 0;
      while (i < n) {
        createId();
        i++;
      }
      return arr;
    }
    // const rules2 = {
    //   phone: [
    //     {
    //       required: true,
    //       message: "请输入手机号",
    //       trigger: "blur",
    //     },
    //     {
    //       min: 11,
    //       max: 11,
    //       message: "长度11个字符",
    //       trigger: "blur",
    //     },
    //   ],
    //   code: [
    //     {
    //       required: true,
    //       message: "请输入验证码",
    //       trigger: "blur",
    //     },
    //     {
    //       min: 4,
    //       max: 6,
    //       message: "长度4到6位",
    //       trigger: "blur",
    //     },
    //   ],
    // };
    let timer;
    onMounted(async () => {
      const res = await getAppid({
        // access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.appid = res.data.data.appid;
      state.id = createUniqueId(10).join("-");
      console.log(state.id);
      if (state.isCode) {
        timer = setInterval(async () => {
          const res = await getResult({
            unique: state.id,
          });
          // console.log(res);
          if (res.data.code === 100000) {
            router.push({
              path: "/",
            });
            sessionStorage.setItem("token", res.data.result.access_token);
            message.success("登入成功");
          }
        }, 1000);
      }
    });
    watch(
      () => state.isCode,
      (val) => {
        if (val) {
          timer = setInterval(async () => {
            const res = await getResult({
              unique: state.id,
            });
            // console.log(res);
            if (res.data.code === 100000) {
              router.push({
                path: "/",
              });
              sessionStorage.setItem("token", res.data.result.access_token);
              message.success("登入成功");
            }
          }, 1000);
        } else {
          clearInterval(timer);
        }
      }
    );
    onUnmounted(() => {
      clearInterval(timer);
    });
    let handleClick = (id) => {
      console.log(id);
      state.activeName = id;
    };
    const sendCode = async () => {
      console.log(regTest(formState2.phone));
      if (regTest(formState2.phone)) {
        const res = await getSendCode({
          mobile: formState2.phone,
          type: 3,
        });
        console.log(res);
        state.sendCodeBtn = true;
        state.timer = setInterval(() => {
          state.timerPhone--;
          if (state.timerPhone == 0) {
            clearInterval(state.timer);
            state.timerPhone = 60;
            state.sendCodeBtn = false;
          }
        }, 1000);
      } else {
        message.warn("请输入正确的手机号");
      }
    };
    let onSubmit = async () => {
      const res = await formRef.value.validate();
      console.log(res);
      if (res) {
        console.log("通过");
        const params = {
          username: formState.name,
          password: formState.password,
          // license: "fz56yse2881nd2r0fz56yse2881nd2r0",
        };
        const res = await login(params);
        sessionStorage.setItem("token", res.data.access_token);
        console.log(res);
        if (res.data.status === 100000) {
          router.push({
            path: "/",
            // query: { username: res.data.data.username },
          });
          message.success("登入成功");
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const Register = () => {
      console.log("zhuusdfh");
      router.push({ path: "/register" });
    };
    const onSubmit2 = async () => {
      const params = {
        mobile: formState2.phone,
        code: formState2.code,
        // license: "fz56yse2881nd2r0fz56yse2881nd2r0",
      };
      const res = await postPhoneLogin(params);
      sessionStorage.setItem("token", res.data.access_token);
      console.log(res);
      if (res.data.status === 100000) {
        router.push({
          path: "/",
          // query: { username: res.data.data.username },
        });
        message.success("登入成功");
      } else {
        message.error("登入失败");
      }
    };
    return {
      ...toRefs(state),
      handleClick,
      onSubmit,
      formState,
      formState2,
      rules,
      formRef,
      Register,
      sendCode,
      onSubmit2,
    };
  },
};
</script>
<style  scoped>
.login {
  width: 100%;
  height: 100%;
  background: url(../assets/logo.jpg) no-repeat;
  background-size: 100% 100%;
  background-color: #f9f9fb;
  /* box-sizing: border-box; */
}
.login h1 {
  margin-left: 20px;
  /* margin-top: 10px; */
}
.login .login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 500px;
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: rgb(90 100 170 / 12%) 0px 2px 24px 0px;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  /* padding: 0 25px */
}
.login .code {
  width: 400px;
  height: 92px;
  margin-bottom: 20px;
  /* width: 100%; */
  text-align: right;
  display: flex;
  /* justify-content: right; */
  justify-content: flex-end;
  align-items: center;
  /* cursor: pointer; */
}
.btn-form {
  width: 100%;
  padding: 0 35px;
}
.active {
  border-bottom: 2px solid #1e9fff;
  color: #1e9fff;
}
.title {
  display: flex;
  font-size: 20px;
  text-align: center;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 30px;
}
.title-saoma {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.item {
  width: 50%;
  padding: 10px 0;
  cursor: pointer;
}
.ant-btn-primary {
  width: 100%;
}
.ant-form {
  box-sizing: border-box;
}
.foolter {
  position: absolute;
  bottom: 125px;
  left: 0;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
}
.sendcode {
  display: flex;
  justify-content: space-between;
}
.ant-row {
  width: 100%;
  margin-right: 20px;
}
.zhuce {
  font-size: 12px;
  color: rgb(136, 156, 183);
  margin-top: 5px;
}
.zhuce label {
  color: rgb(47, 130, 248);
  cursor: pointer;
}
.code-text {
  color: rgb(47, 130, 248);
  font-size: 14px;
  box-shadow: unset;
  border: 1px solid rgba(70, 90, 250, 0.06);
  background: rgba(70, 90, 250, 0.06);
  padding: 5px 10px;
  border-radius: 2px;
}
.sanjiao {
  border-top: 7px solid transparent;
  border-left: 14px solid rgba(70, 90, 250, 0.06);
  border-bottom: 7px solid transparent;
}
.btn-code {
  width: 100%;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-code {
  width: 200px;
  height: 200px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}
.title-open {
  color: rgb(102, 102, 102);
  margin-top: 10px;
}
</style>