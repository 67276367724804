import http from "../axios/http"

export let login=(params)=>{
    return http.post("/auth/login",params)
}
//微信绑定获取appid（已改）
export let getAppid = (params) => {
    return http.get("/index/wx_info", { params })
}
//获取微信登入回调结果（已改）
export let getResult = (params) => {
    return http.get("/index/wx_result", { params })
}
//手机号登入
export let postPhoneLogin = (params) => {
    return http.post("/phone/login",  params )
}
//发送验证码
export let getSendCode = (params) => {
    return http.post("/auth/send_code", params)
}