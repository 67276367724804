export let regTest = (phone) => {
  let reg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
  return reg.test(phone);
};
//固定电话校验
export let telTest = (rule, value) => {
  if (
    /^\d{3,4}-\d{7,8}$/.test(value) ||
    /^\d{4}-\d{7}|\d{3}-\d{8}$/.test(value)
  ) {
    return Promise.resolve("成功");
  } else {
    return Promise.reject("失败");
  }
};
//邮箱验证
export let emailTest = (rule, value) => {
  if (/^([a-zA-Z\d])((\w|-)+\.?)+@([a-zA-Z\d]+\.)+[a-zA-Z]{2,6}$/.test(value)) {
    return Promise.resolve("成功");
  } else {
    return Promise.reject("失败");
  }
};

